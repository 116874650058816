import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  layout: "unauth",
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    return {
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      logoutVisible: false,
      authConfig: {
        confirmSignInConfig: {},
        confirmSignUpConfig: {},
        forgotPasswordConfig: {},
        signInConfig: {
          header: ""
        },
        signUpConfig: {
          header: "Sign Up Here",
          signUpFields: [{
            label: this.I18nFn("Username"),
            key: "username",
            required: true,
            type: "string",
            displayOrder: 1
          }, {
            label: this.I18nFn("Password"),
            key: "password",
            required: true,
            type: "password",
            displayOrder: 2
          }, {
            label: this.I18nFn("Email"),
            key: "email",
            required: true,
            type: "string",
            displayOrder: 3
          }, {
            label: this.I18nFn("Invite Code"),
            key: "custom:inviteCode",
            required: true,
            type: "string",
            displayOrder: 4
          } // {
          //   label: this.I18nFn(""),
          //   key: "email",
          //   required: true,
          //   type: "string",
          //   displayOrder: 3
          // },
          // {
          //   label: this.I18nFn("Email"),
          //   key: "email",
          //   required: true,
          //   type: "string",
          //   displayOrder: 3
          // }
          // {
          //   label: this.I18nFn("Phone Number"),
          //   key: "phone_number",
          //   required: false,
          //   displayOrder: 4
          // }
          ],
          hiddenDefaults: ["phone_number"]
        }
      }
    };
  },
  computed: {
    roleError: function roleError() {
      return this.$store.state.roleError;
    }
  }
});